<template>
  <div class="col-md-12">
    <div class="modal fade modal_cust show" v-if="modalId=='commissionDetails'" :class="modalState?'show':''" tabindex="-1" role="dialog" style="display: block;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{details.title}}
            </h5>
            <a class="close"  @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form @submit.prevent="editCommission" method="post" class="bg-theam-secondary" enctype="multipart/form-data">
            <div class="modal-body row">
              <div class="col-md-12 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Commission Details</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-6 mr-0 pr-0">
                            <label for="title">Title</label>
                          </div>
                          <div class="col-md-6" :class="{ invalid: $v.formData.title.$error }">
                            <input
                                    type="text"
                                    class="form-control border-radius-0"
                                    placeholder="Title"
                                    id="title"
                                    @blur="$v.formData.title.$touch()"
                                    v-model.trim="formData.title"
                                    required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.title.$error">
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.title.required"
                            >Title field is required.</p>
                            <p
                                    class="error_level"
                                    v-if="!$v.formData.title.minLength"
                            >At least 2 character title.</p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Commission</label>
                          </div>
                          <div class="col-md-6">
                            <input
                                    type="number"
                                    step="any"
                                    class="form-control border-radius-0"
                                    placeholder="Commission"
                                    v-model.trim.number="formData.commission"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>Commission Type</label>
                          </div>
                          <div class="col-md-6 d-flex">
                            <input
                                    type="radio"
                                    class="form-control border-radius-0"
                                    id="rupees"
                                    v-model.trim.number="formData.type"
                                    value="1"
                            />
                            <label for="rupees" class="mt-2 ml-2">Rupees</label>
                            <input
                                    type="radio"
                                    class="form-control border-radius-0 ml-5"
                                    id="percentage"
                                    v-model.trim.number="formData.type"
                                    value="2"
                            />
                            <label for="percentage" class="mt-2 ml-2">Percentage</label>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-6 mr-0 pr-0">
                            <label>For</label>
                          </div>
                          <div class="col-md-6">
                            <select
                                    class="form-control border-radius-0"
                                    v-model.trim.number="formData.for"
                            >
                              <option value="1">Agent</option>
                              <option value="2">Partner</option>
                              <option value="3">Affiliate</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                <p :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                >{{eventMessage}}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* disable eslint */
  import { mapGetters } from "vuex";
  import {
    maxLength,
    minLength,
    numeric,
    required,
  } from "vuelidate/lib/validators";
  export default {
    data() {
      return {
        details: [],
        formData: {
          title: "",
          commission: "",
          type: 1,
          for: 1,
        },
      };
    },
    validations: {
      formData: {
        title: { required, minLength: minLength(2), maxLength: maxLength(255) },
        commission: { required, numeric },
        type: { required, numeric },
        for: { required, numeric },
      },
    },
    methods: {
      editCommission() {
        this.$store.commit(
                "setApiUrl",
                `api/settings/commission/${this.details.id}`
        );
        this.$store.commit("updateData", this.formData);
      },
    },
    computed: {
      ...mapGetters([
        "processing",
        "eventMessage",
        "dataLists",
        "dataId",
        "modalId",
        "modalState",
      ]),
    },
    watch: {
      dataId(value) {
        this.details = this.dataLists.find(function (data) {
          return data.id == value;
        });
        this.formData.title = this.details.title;
        this.formData.commission = this.details.commission;
        this.formData.type = this.details.type;
        this.formData.for = this.details.for;
      }
    },
  };
</script>